/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2023-05-11 11:09:27
 */

import { message } from 'ant-design-vue';

/**
 * @description: 内容拼接
 * @param {*} data
 * @return {*}
 */
export function parameterChange(data) {
  let res = ``;
  for (const key in data) {
    let dataKey = '';
    if (data[key] || data[key] === 0) dataKey = data[key];
    res = `${res}&${key}=${dataKey}`;
  }
  return res.substr(1);
}

// 复制文本
export function copyText(text) {
  return new Promise((resolve) => {
    var textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed'; // 使其在视口之外
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    resolve();
  });
}

// 获取file名称
export function getFileName(info) {
  if (!info) return;
  return info.split('/').pop();
}

export function getFormData(arr) {
  const temp = {};
  arr.forEach((item) => {
    if (['image'].includes(item.type)) {
      const imgArr = item.value.map((imgItem) => imgItem.url);
      temp[item.key] = imgArr.join(',') || '';
    } else if (['multiple'].includes(item.type)) {
      temp[item.key] = item.value.join(',') || '';
    } else {
      temp[item.key] = item.value || '';
    }
  });
  console.log(temp);

  return temp;
}

export function handleReview(item) {
  return {
    uid: Date.now() + Math.random(),
    name: getFileName(item),
    status: 'done',
    url: item,
  };
}

export function getFormValue(obj, item) {
  let val;
  if (obj?.hasOwnProperty(item.key)) {
    if (['image'].includes(item.type)) {
      val = [];
      let arr = obj[item.key]?.split(',');
      arr.forEach((item) => {
        if (item) {
          val.push(handleReview(item));
        }
      });
    } else if (['multiple'].includes(item.type)) {
      val = obj[item.key]?.split(',');
    } else {
      val = obj[item.key] + '';
    }
  } else {
    val = ['image', 'multiple'].includes(item.type) ? [] : undefined;
  }
  return val;
}

export function judgeSubmit(arr) {
  let flag = true;
  for (let item of arr) {
    if (
      ((item.hasOwnProperty('require') && item.require) || !item.hasOwnProperty('require')) &&
      ['', undefined, []].includes(item.value)
    ) {
      flag = false;
      message.error(
        `请${['select', 'multiple', 'checkbox', 'image'].includes(item.type) ? '选择' : '填写'}${item.label}！`,
      );
      break;
    }
  }
  return flag;
}
