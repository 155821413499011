<template>
  <div ref="modalRef">
    <a-modal
      :open="show"
      :footer="null"
      :getContainer="() => $refs.modalRef"
      :afterClose="afterClose"
      :title="`${id ? '编辑' : '新建'}${title}`"
      destroyOnClose
      @cancel="handleClose"
    >
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item
          :class="{ require: showLabel(item) }"
          :label="item.label"
          :key="item.key"
          v-for="item in form"
        >
          <formItem :item="item" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
          <a-space>
            <a-button type="primary" @click="onSubmit">确定</a-button>
            <a-button @click="handleClose">取消</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
  
<script setup>
import { formItem } from '@/component';
import { getFormData, getFormValue, judgeSubmit } from '@/utils';
import { ref, getCurrentInstance } from 'vue';

const { proxy } = getCurrentInstance();
const props = defineProps({
  title: { type: String, default: '' },
  idField: { type: String, default: 'id' },
  api: { type: Object, default: () => {} },
  formArr: { type: Array, default: () => [] },
});

const modalRef = ref();

const showLabel = (item) => {
  if (item.hasOwnProperty('require') && !item.require) {
    return false;
  }
  return true;
};

const id = ref(),
  show = ref(false),
  handleOpen = (idVal) => {
    if (idVal) {
      id.value = idVal;
      getDetail();
    }
    form.value = props.formArr;
    show.value = true;
  },
  handleClose = () => {
    show.value = false;
  },
  afterClose = () => {
    id.value = undefined;
    form.value = form.value.map((item) => {
      if (['image'].includes(item.type)) {
        item.value = [];
      } else if (['inputNumber'].includes(item.type)) {
        item.value = 0;
      } else {
        item.value = undefined;
      }
      return item;
    });
  };
defineExpose({ handleOpen });

const emits = defineEmits(['getList']);
const form = ref([]),
  getDetail = () => {
    proxy.$api[props?.api?.details]({ [props.idField]: id.value }).then((res) => {
      if (res) {
        form.value.forEach((item) => {
          item.value = getFormValue(res.data, item);
        });
      }
    });
  },
  onSubmit = () => {
    if (!judgeSubmit(form.value)) {
      return;
    }
    const params = { ...getFormData(form.value) };

    let api = props.api.add;
    if (id.value) {
      params[props.idField] = id.value;
      api = props.api.update;
    }
    proxy.$api[api](params).then((res) => {
      if (res) {
        proxy.$message.info(res.msg);
        handleClose();
        emits('getList');
      }
    });
  };

const labelCol = { style: { width: '150px' } },
  wrapperCol = { span: 14 };
</script>
  
<style lang="less" scoped>
:deep(.ant-modal) {
  .ant-modal-header {
    margin-bottom: 40px;
  }

  .ant-form-item {
    &.require {
      .ant-form-item-label > label {
        &::before {
          content: '*';
          color: #f5222d;
          font-size: 18px;
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .ant-input-number {
      width: 100%;
    }
  }
}
</style>
  