<template>
  <!-- 日期 -->
  <template v-if="item?.type == 'date'">
    <a-date-picker v-model:value="item.value" />
  </template>

  <!-- 范围日期 -->
  <template v-else-if="item?.type == 'dateRange'">
    <a-range-picker v-model:value="item.value" :show-time="item.showTime" />
  </template>

  <!-- 输入框 -->
  <template v-else-if="item?.type == 'input'">
    <a-input
      v-model:value="item.value"
      placeholder="请输入"
      class="m-field"
      :label="item?.label"
      :readonly="item?.readonly"
      :style="{ '--color': item?.color || '#000' }"
    ></a-input>
  </template>

  <!-- 数字 -->
  <template v-else-if="item?.type == 'inputNumber'">
    <a-input-number
      v-model:value="item.value"
      placeholder="请输入"
      class="m-field"
      :min="1"
      :label="item?.label"
      :readonly="item?.readonly"
      :style="{ '--color': item?.color || '#000' }"
    ></a-input-number>
  </template>

  <!-- 多行输入 -->
  <template v-else-if="item?.type == 'textarea'">
    <a-textarea
      v-model:value="item.value"
      placeholder="请输入"
      class="m-field"
      :label="item?.label"
      :readonly="item?.readonly"
      :style="{ '--color': item?.color || '#000' }"
    ></a-textarea>
  </template>

  <!-- 选择 -->
  <template v-else-if="item?.type == 'select'">
    <a-select v-model:value="item.value" :options="item.options" placeholder="请选择"></a-select>
  </template>

  <!-- 单选 -->
  <template v-else-if="item?.type == 'radio'">
    <a-radio-group
      v-model:value="item.value"
      :options="item.options"
      @change="emits('change', { item })"
    ></a-radio-group>
  </template>

  <!-- 多选 -->
  <template v-else-if="item?.type == 'checkbox'">
    <a-checkbox-group v-model:value="item.value" :options="item.options" />
  </template>

  <!-- 开关 -->
  <template v-else-if="item?.type == 'switch'">
    <a-switch v-model:checked="item.value" />
  </template>

  <!-- 图片 -->
  <!-- { key: 'cover', type: 'image', label: '类别封面', value: [], path: 'projects/img/category', maxCount: 3} -->
  <template v-else-if="item?.type == 'image'">
    <a-upload
      v-model:file-list="item.value"
      list-type="picture-card"
      accept="image/*"
      :max-count="item.maxCount"
      :customRequest="(e) => onUploadImg(e, item)"
    >
      点击上传
    </a-upload>
  </template>

  <template v-else-if="item?.type == 'table'">
    <a-table
      :rowKey="item.rowKey"
      :pagination="item?.pagination"
      :columns="item?.columns"
      :dataSource="item?.dataSource"
      :row-selection="{
        hideSelectAll: true,
        selectedRowKeys: selectedKeys,
        onChange: onSelectChange,
        onSelect: (selectedRowKeys, selectedRows) => {
          console.log(selectedRowKeys, selectedRows);
          item.value.push(selectedRowKeys[item.rowKey]);
        },
      }"
      @change="(e) => item.handleTableChange(e, item)"
    ></a-table>
  </template>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue';
import { qnUpload } from '@/utils';

const { proxy } = getCurrentInstance();
const props = defineProps({
  item: { type: Object, default: () => {} },
});
const emits = defineEmits(['change']);

const onUploadImg = (info, item) => {
  const type = 'img';
  qnUpload(info, item.path, type)
    .then((res) => {
      if (res) {
        if (props.item.maxCount == 1) {
          props.item.value = [res];
        } else {
          props.item.value[props.item.value.length - 1] = res;
        }
      }
    })
    .catch((err) => {
      proxy.$message.info(err.msg);
    });

  const selectedKeys = ref([]),
    onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
};
</script>

<style lang="less" scoped></style>